<template>
  <div class="min-h-screen flex flex-col bg-[#0A0A0A]">
    <Navbar :isAuthenticated="isAuthenticated" @logout="logout" class="sticky top-0 z-10" />

    <div class="flex-grow">
      <main class="flex-grow py-8">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <slot name="content" />
        </div>
      </main>
    </div>

    <Footer />
  </div>
</template>

<script setup>
import { tryOnMounted } from '@vueuse/core';
const { isMobile, isDesktop } = useDevice();
import { useSlots } from 'vue';
import { XMarkIcon } from '@heroicons/vue/20/solid'
import { useStore, useAudioPlayerStore } from '~/store'
import mixpanel from 'mixpanel-browser'
let store = useStore()
let audioPlayerStore = useAudioPlayerStore()
const slots = useSlots()

const supabase = useSupabaseClient()
const hasHeaderContent = computed(() => !!slots.header)

let isAuthenticated = ref(false)

let logout = async () => {
  await supabase.auth.signOut()
  window.location.href = '/'
}

tryOnMounted(() => {
  supabase.auth.getSession().then(({ data: { session } }) => {
    if (session) {
      isAuthenticated.value = true
      store.setIsAuthenticated(true)
      mixpanel.identify(session.user.id)
    }
  })

  supabase.auth.onAuthStateChange((event, session) => {
    console.log("supabase auth state change", event, session)

    if (event === 'INITIAL_SESSION') {
      if (session?.user) {
        isAuthenticated.value = true
        store.setIsAuthenticated(true)
      }
    }

    if (event === 'SIGNED_IN') {
      isAuthenticated.value = true
      store.setIsAuthenticated(true)
      mixpanel.identify(session.user.id)
    } else if (event === 'SIGNED_OUT') {
      isAuthenticated.value = false
      store.setIsAuthenticated(false)

    }
  })
})
</script>